<template>
  <el-container>
    <!--  顶部信息-->
    <el-header class="front-header">
      <frontHead></frontHead>
    </el-header>
    <!--  栏目链接-->
    <el-main class="front-main">
      <!-- 轮播1-->
      <div class="block carousel">
        <el-carousel height="800px" initial-index="1" ref="carousel1">
          <el-carousel-item v-for="(rotation,index) in picList1" :key="index">
            <img :src="serverURL+rotation.image" style="cursor: pointer" @click="openUrl(rotation.url,0,rotation.id)">
<!--            <div class="title" >
              <span>{{ rotation.title }}</span>
            </div>-->
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="title">创新不止30年·未来无限可能</div>
      <div class="sub-title">我们的存在是为了重新定义您对厨房电器的期望，创造人类对厨房生活的一切美好向往</div>
      <div class="slogan-list">
        <el-row>
          <el-col :span="6" v-for="slogan in sloganList">
            <img :src="slogan.icon"/>
            <p>{{slogan.title}}</p>
          </el-col>
        </el-row>
      </div>
      <!-- 轮播2-->
      <div class="block carousel">
        <el-carousel height="800px" initial-index="1" ref="carousel2">
          <el-carousel-item v-for="(rotation,index) in picList2" :key="index">
            <img :src="serverURL+rotation.image" style="cursor: pointer" @click="openUrl(rotation.url,0,rotation.id)">
            <!--            <div class="title" >
                          <span>{{ rotation.title }}</span>
                        </div>-->
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="title">经典厨电·雅享美好时光</div>
      <div class="sub-title">以技术创新带动迭代升级，为热爱生活方式的您推送高颜值高品质的产品</div>
<!--      置顶产品-->
      <div class="top-product-box">
        <div class="top-product">
          <div class="left">
            <img src="../../assets/images/front/top-product.jpg">
          </div>
          <div class="right">
            <div class="product-list">
              <div class="product" v-for="p in topProductList" @mouseenter="inPic(p)" @mouseleave="outPic(p)" @click="contentDetail(productChannelId,'产品中心',p.id)">
                <img v-if="!p.imageError" :src="serverURL+p.thumbnail" @error="p.imageError=true">
                <img v-else src="../../assets/images/front/no-pic-mbzs.png">
                <span>{{p.title}}</span>
              </div>
            </div>
          </div>
        <div style="text-align: center;font-size: 16px;cursor: pointer;" @click="viewMore(1,'产品中心',3,'燃气灶')">更多产品&gt;&gt;</div>
        </div>
      </div>
      <!--      常见问题-->
      <div class="problem-box">
        <div class="problem-list">
          <div class="channel-title">常见问题<span style="float:right;font-size: 16px;cursor: pointer;" @click="viewMore(2,'服务中心',4,'常见问题')">更多问题&gt;&gt;</span></div>
          <div class="problem-item" v-for="p in problemList">
            <div class="problem-title">
              <span>Q</span>{{strUtil.cutTitle(p.title,70)}}
            </div>
            <div class="problem-content">
<!--              <span>A</span>-->
              <div class="content" v-html="strUtil.cutTitle(p.content,160)"></div>
            </div>
          </div>
        </div>
      </div>
      <!--超链接-->
      <div class="friendlink-box" v-show="linkList.length>0">
        <div class="friendlink-list">
          <div class="channel-title">友情链接</div>
          <div class="link-box">
            <el-link :underline="false" type="primary" v-for="l in linkList" @click="openUrl(l.url,1,l.id)">{{l.name}}</el-link>
          </div>
        </div>
      </div>
    </el-main>
    <!--  底部信息-->
    <el-footer class="front-footer">
      <frontFooter></frontFooter>
    </el-footer>
  </el-container>
</template>

<script>
import frontHead from './head.vue'
import frontFooter from './footer.vue'
import friendlinkApi from "@/api/info/friendlink";
import contentApi from "@/api/info/content";
import rotationApi from "../../api/info/rotation";
import config from '@/api/config'
import strUtil from "@/utils/strUtil";
import dateUtil from "@/utils/dateUtil";

export default {
  name: "index",
  components: {
    frontHead, frontFooter
  },
  data() {
    return {
      picSize: 20,
      serverURL: '',
      picList1: [],
      picList2: [],
      productChannelId: 1,
      problemChannelId: 4,
      problemList: [],
      linkList: [],
      strUtil,
      dateUtil,
      sloganList:[
        {icon:require('@/assets/images/front/slogan1.png'),title:'探究新可能'},
        {icon:require('@/assets/images/front/slogan2.png'),title:'忠于产品'},
        {icon:require('@/assets/images/front/slogan3.png'),title:'与用户共振'},
        {icon:require('@/assets/images/front/slogan4.png'),title:'探索未知'},
      ],
      topProductList:[
        /*{thumbnail:require('@/assets/images/front/product.jpg'),title:'1111'},
        {thumbnail:require('@/assets/images/front/product.jpg'),title:'2222'},
        {thumbnail:require('@/assets/images/front/product.jpg'),title:'3333'},
        {thumbnail:require('@/assets/images/front/product.jpg'),title:'4444'},
        {thumbnail:require('@/assets/images/front/product.jpg'),title:'5'},
        {thumbnail:require('@/assets/images/front/product.jpg'),title:'6'},*/
      ],
      imageError:false,
    }
  },
  mounted() {
    this.queryRotation();
    this.getIndexChannelContent();
    this.queryLink('底部链接');
    this.serverURL = config.server;
  },
  methods: {
    // 进入图片
    inPic(p){
      p.showTitle=true;
    },
    // 移出图片
    outPic(p){
      p.showTitle=false;
    },
    //查询轮播列表
    queryRotation() {
      rotationApi
          .queryRotation({
            pageSize: this.picSize,
            curPage: 1,
            status:1,
          })
          .then((res) => {
            let picList = res.data.data.records;
            for(let i=0;i<picList.length;i++){
              if(picList[i].groupName=='001'){
                this.picList1.push(picList[i]);
              }else if(picList[i].groupName=='002'){
                this.picList2.push(picList[i]);
              }
            }
            this.$nextTick(() => {
              this.$refs.carousel1.setActiveItem(0);
              this.$refs.carousel2.setActiveItem(0);
            })
          });
    },
    // 根据栏目id查询前5条内容
    getContentByChannelId(channelId, listName,size,isTop) {
      contentApi.pageContentByChannelId({
        pageSize: size,
        curPage: 1,
        channelId: channelId,
        isTop: isTop,
      }).then((res) => {
        this[listName] = res.data.data.records;
      })
    },
    // 查询各首页栏目文章
    getIndexChannelContent() {
      // 产品中心
      this.getContentByChannelId(this.productChannelId,'topProductList',6,1);
      // 常见问题
      this.getContentByChannelId(this.problemChannelId,'problemList',6,'');
    },
    // 获取友情链接
    queryLink(groupName) {
      friendlinkApi.queryLink({groupName:groupName}).then((res) => {
        this.linkList = res.data.data;
      })
    },
    // 打开友情链接或轮播图跳转地址
    openUrl(url,type,id) {
      // 点击轮播图
      if(type==0){
        // 记录点击量
        rotationApi.click(id);
      }else{// 点击友情链接
        // 记录点击量
        friendlinkApi.click(id);
      }
      if(url!=null&&url!=''){
        window.open(url);
      }
    },
    // 更多跳转
    viewMore(parentChannelId, parentChannelName,channelId, channelName) {
      this.$router.push({
        path: '/channel',
        query: {
          parentChannelId:parentChannelId,
          parentChannelName:parentChannelName,
          channelId: channelId,
          channelName: channelName
        }
      });
    },
    // 文章详情
    contentDetail(channelId, channelName, contentId) {
      this.$router.push({
        path: '/content',
        query: {
          channelId: channelId,
          channelName: channelName,
          parentChannelId: channelId,
          parentChannelName: channelName,
          contentId: contentId
        }
      });
    },
  }
}
</script>

<style scoped>
.carousel {
  /*margin-bottom: 20px;*/
}

.el-carousel__item img {
  color: #475669;
  font-size: 14px;
  /*opacity: 0.75;*/
  margin: 0;
  text-align: center;
  width: 100%;
  height: 100%;
}

.el-carousel__item .title {
  min-width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-weight: bold;
  position: absolute;
  bottom: 15px;
  left: 0px;
  right: 0px;
  color: #ffffff;
}

.el-carousel__item .title span {
  padding: 0 10px;
}

.el-carousel__item .title:hover {
  text-decoration: underline;
}

/*角标样式*/
/deep/.el-badge__content.is-fixed {
  transform: translateY(-40%) translateX(120%);
}
.title{
  background-color: #000000;
  color: #FFFFFF;
  font-size: 44px;
  text-align: center;
  line-height: 90px;
}
.sub-title{
  background-color: #000000;
  color: #FFFFFF;
  font-size: 24px;
  text-align: center;
  line-height: 78px;
}
.slogan-list{
  background-color: #000000;
  color: #FFFFFF;
  font-size: 18px;
}
.slogan-list .el-row{
  margin: 0 auto;
  width: 1240px;
}
.slogan-list .el-row .el-col{
  text-align: center;
}

.top-product-box{
  background-color: #000000;
  color: #FFFFFF;
}
.top-product{
  margin: 0 auto;
  width: 1240px;
}
.top-product .left{
  width: 301px;
  display: inline-block;
}
.top-product .right{
 width: calc(100% - 321px);
  display: inline-block;
  vertical-align: top;
}
.product-list{
  padding-left: 20px;
}
.product{
  display: inline-block;
  margin: 0 15px 16px 0px;
  cursor: pointer;
}

.product img{
  height: 400px;
  width: 280px;
}
.product span{
  display: block;
  font-size: 20px;
  width: 100%;
  text-align: center;
  background-color: rgba(0,0,0,0.3);
  color: #FFFFFF;
  position: relative;
  bottom: 40px;
  line-height: 40px;
  /*height: 0;*/
}

.problem-box{
  background-color: #000000;
  color: #FFFFFF;
  padding: 20px;
}
.problem-box .problem-list{
  margin: 0 auto;
  width: 1240px;
}
.problem-item{
  margin-bottom: 15px;
  border: #FFFFFF solid 1px;
}

.problem-title{
  line-height: 40px;
  border-bottom: #FFFFFF solid 1px;
}
.problem-title span{
  display: inline-block;
  text-align: center;
  color: #696969;
  width: 40px;
  background-color: #FFFFFF;
  margin-right: 10px;
}
.problem-content{
  line-height: 40px;
}
/*.problem-content span{
  display: inline-block;
  text-align: center;
  color: #FFFFFF;
  width: 40px;
  margin-right: 10px;
}*/
.problem-content .content{
  padding: 0 10px;
}
.channel-title{
  font-size: 28px;
  padding: 10px 0;
}

.friendlink-box{
  background-color: #000000;
  color: #FFFFFF;
}
.friendlink-list{
  width: 1240px;
  margin: 0 auto;
  font-size: 16px;
}
.link-box{
  border-bottom: #FFFFFF solid 1px;
  /*border-radius: 10px;*/
}
.link-box .el-link{
  padding: 10px;
}
</style>